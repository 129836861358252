import { observer } from 'mobx-react-lite';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FormioEvent } from 'src/core/Formio.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { TaskRepresentation } from 'src/generated-api-client';
import { ProcessPageRouterParams } from 'src/pages/ProcessPage/ProcessPage';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export type EntityProcessProps = {
    entityUrl: string;
    entityStore: BasicStore<any>;
};

export const EntityProcess = observer(
    ({ entityUrl, entityStore }: EntityProcessProps) => {
        const { params } = useRouteMatch<
            ProcessPageRouterParams & { entityId: string }
        >();

        const context = entityStore?.currentItem;

        const { t } = useTranslation();
        const processDefinition = useMemo(() => {
            return ProcessDefinitionsStore.getProcessDefinition(
                params.processKey,
            );
        }, [params.processKey, ProcessDefinitionsStore.list]);

        useEffect(() => {
            ProcessDefinitionsStore.loadItem(params.processKey);
        }, [params.processKey]);

        const process = ProcessStoreProvider.getInstance(params.processKey);

        useLayoutEffect(() => {
            if (process && process.isEmptyContext) {
                process.setContext(context);
            }
        }, [process, context]);

        useEffect(() => {
            if (processDefinition) {
                process.loadForm(processDefinition.startFormKey);
            }

            return () => {
                process.itemLoader.reset();
                process.formLoader.reset();
            };
        }, [processDefinition]);

        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([
                ProcessDefinitionsStore.listLoader,
                process.itemLoader,
                process.formLoader,
            ]);
        }, [process]);

        if (loadingStatus.isLoading) {
            return <StyledSpinner />;
        }

        const hasL10nTitleKey = Boolean(
            processDefinition?.extensions?.l10nTitleKey,
        );
        const title = hasL10nTitleKey
            ? t(`${processDefinition?.extensions?.l10nTitleKey}`, {
                  context: processDefinition,
              })
            : processDefinition?.name;

        console.warn(
            process.getInitialVariables(),
            'process.getInitialVariables()',
        );

        return (
            <StyledWrapper>
                <StyledProcessHeader>{title}</StyledProcessHeader>
                <FormioForm
                    form={
                        (ProcessDefinitionsStore.currentItem as any)
                            ?.form as any
                    }
                    submission={process.getInitialVariables()}
                    onSubmit={getSubmitHandler(params.processKey)}
                    normalizeSubmission
                    onCustomEvent={(event) => {
                        if (event.type === 'navigateToEntityCard') {
                            event.event.preventDefault();
                            Router.navigate(entityUrl);
                        }

                        return Promise.resolve();
                    }}
                />
            </StyledWrapper>
        );
    },
);

const StyledProcessHeader = styled.h1`
    margin-bottom: 0;

    @media (max-width: 576px) {
        font-size: 2rem;
    }
`;

const submitForm = async (processKey: string, data: any) => {
    const process = ProcessStoreProvider.getInstance(processKey);
    await process.update(processKey, data);
    const tasks = process.nextTasks as any as TaskRepresentation[];
    if (tasks?.length) {
        Router.navigate(
            UrlHelper.getTargetUrl(RoutePaths.task, {
                taskId: tasks[0].id!,
            }),
        );
    } else {
        Router.navigate(RoutePaths.index);
    }
};

const getSubmitHandler = (processKey: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        const { data } = values;

        await submitForm(processKey, data);
    };
};

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const StyledWrapper = styled.div`
    max-width: 700px;
    margin: 0 auto;
    .datagrid-table {
        border: none !important;
    }
`;
