import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ListPage } from 'src/components/ListPage/ListPage';
import { ListPageItem } from 'src/components/ListPage/ListPageItem';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { EntityStatus } from 'src/core/StatusInfo';
import { LoanApplication } from 'src/generated-api-client';
import { ApplicationStatusInfoMap } from 'src/pages/ApplicationsPage/ApplicationStatusInfo';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const ApplicationsPage = observer(() => {
    const { t } = useTranslation();

    return (
        <ListPage
            pageName="applications"
            store={ApplicationsStore}
            title={t('Page.Applications.Title')}
            renderItem={(item: LoanApplication) => {
                const amountRequested = NumberHelper.formatToUsd(
                    item.amountRequested!,
                );

                return (
                    <ListPageItem
                        to={UrlHelper.getTargetUrl(RoutePaths.application, {
                            applicationId: item.id!.toString(),
                        })}
                    >
                        <Row wrap={false} gutter={5}>
                            <Col flex="auto">
                                <StyledName>
                                    {t('Page.Applications.ListItem.Title', {
                                        number: `#${item?.number}`,
                                        amountRequested,
                                    })}
                                </StyledName>
                            </Col>
                            <Col flex="none">
                                <EntityStatus
                                    entityName="application"
                                    statusInfoMap={ApplicationStatusInfoMap}
                                    status={item.status}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledCreatedAt>
                                    {item.requestedAt &&
                                        DateHelper.format(item.requestedAt)}
                                </StyledCreatedAt>
                            </Col>
                        </Row>
                    </ListPageItem>
                );
            }}
        />
    );
});

const StyledName = styled.div`
    font-weight: 600;
    font-size: 30px;
    line-height: 105%;
    letter-spacing: -0.03em;
    color: var(--form-text-color);

    @media (max-width: 576px) {
        font-size: 16px;
    }
`;

const StyledCreatedAt = styled.div`
    font-size: 16px;
    line-height: 165%;
    color: #828b94;
    margin-top: 12px;

    @media (max-width: 576px) {
        font-size: 11px;
    }
`;
