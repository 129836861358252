import { LoanApplicationBorrowerAllowed } from 'src/generated-api-client';
import { borrowerApi, bpmsResourcesManagerApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationsStoreClass extends BasicStore<LoanApplicationBorrowerAllowed> {
    formKey = 'embedded:deployment:forms/bp/bp-01-loan-application-info.json';
    api: BasicStoreApi<LoanApplicationBorrowerAllowed> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerApplications1(
                    0,
                    100,
                    undefined,
                    options,
                ),
            );

            return response.content || [];
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerApplication(id, options),
            );
        },

        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(this.formKey, options),
            );
        },
    };
}
export const ApplicationsStore = new ApplicationsStoreClass();
